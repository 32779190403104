import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { routes } from '../constants/pathRoutes'

const PublicRoute = ({ component: Component, isAuthenticated, ...restProps }) => {
  return (
    <Route 
      {...restProps}
      component={
        (props) => (
          (!isAuthenticated)
          ? <Component {...props} />
          : <Redirect to={routes.home} />
        )
      }
    />
  )
}

export default PublicRoute
