export const routes = {
  initial        : '/',
  home           : '/home',
  restorePassword: '/restorePassword',
  listVehicles   : '/listVehicles',
  vehicles       : '/Vehicles',
  profile        : '/profile',
  history        : '/history',
  favoriteTrips  : '/favorite-trips',
  detailTrip     : '/trip-details/:idRoute', 
  editVehicle    : '/editVehicle/:id',
  detailMap      : '/map/:routes/:id',
  config         : '/config',
  configVehicle  : '/config/:idVehicle'
}