import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const alertAuthLogin = ( code ) => {
  	const MySwal = withReactContent(Swal);

  switch (code) {
        case "auth/wrong-password":
          MySwal.fire({
            title:
              "La contraseña no es válida o el usuario no tiene una contraseña.",
            icon: "error"
          });
          break;
        case "auth/network-request-failed":
          MySwal.fire({
            title:
              "Se ha producido un error de red (como tiempo de espera, conexión interrumpida o host inaccesible).",
            icon: "error"
          });
          break;
        case "auth/invalid-email":
          MySwal.fire({
            title: "La dirección de correo electrónico está mal formateada.",
            icon: "error"
          });
          break;
        default:

          MySwal.fire({
            title:
              "Porfavor volver a digitar la información ingresada en el Login",
            icon: "error"
          });
          break;
    }
}

export const alertAuthRegister = ( code ) => {
  const MySwal = withReactContent(Swal);
    switch (code) {
        case "auth/email-already-in-use":
          MySwal.fire({
            title: "La dirección de email ya existe.",
            icon: "error"
          });
          break;
        case "auth/weak-password":
          MySwal.fire({
            title: "La contraseña debe tener mínimo 6 caracteres",
            icon: "error"
          });
          break;
        case "auth/network-request-failed":
          MySwal.fire({
            title:
              "Se ha producido un error de red (como tiempo de espera, conexión interrumpida o host inaccesible).",
            icon: "error"
          });
          break;
        default:
          MySwal.fire({
            title: "Porfavor validar la información ingresada en el formulario",
            icon: "error"
          });
          break;
      }
}