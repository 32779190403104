import React, { useContext, useEffect } from 'react'
import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { routes } from '../constants/pathRoutes'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import { AuthContext } from '../context/auth/AuthContext'

const Home = lazy(() => import('../pages/Home') )
const Profile = lazy(() => import('../pages/Profile') )
const Vehicles = lazy(() => import('../Settings/Vehicles') )
const SignInSide = lazy(() => import('../UI/ContainerLogin') )
const EditVehicle = lazy(() => import('../Settings/EditVehicle') )
const TripDetails = lazy(() => import('../Settings/TripDetails') )
const ListVehicles = lazy(() => import('../Settings/ListVehicles') )
const TripDetailsMap = lazy(() => import('../Settings/TripDetailsMap') )
const RestorePassword = lazy(() => import('../pages/auth/RestorePassword') )
const HistoryTrips = lazy(() => import('../pages/history-trip/HistoryTrips') )
const FavoriteTrips = lazy(() => import('../pages/favorite-trip/FavoriteTrips') )
const ConfigPage = lazy(() => import('../pages/config/ConfigPage') )
const DetailVehicle = lazy(() => import('../pages/config/DetailVehicle') )
const PaymentPage = lazy(() => import('../pages/payment-plans/PaymentPage') )


const AppRouter = () => {

  const { authState:{ isAuth }, getCurrentUser } = useContext(AuthContext)

  useEffect(() => {
     getCurrentUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Router>
      <Suspense fallback={<h5>Cargando...</h5>}>
        <Switch>
              {/* rutas para iniciar sesión */}
              <PrivateRoute 
                isAuthenticated={isAuth} 
                path={routes.home} 
                component={Home} 
              />
              <PublicRoute 
                exact 
                isAuthenticated={isAuth} 
                path={routes.initial} 
                component={SignInSide}
              />
              <Route
                exact 
                path={routes.restorePassword} 
                component={RestorePassword}
              />
              <Route
                exact 
                path={'/payment'} 
                component={PaymentPage}
              />
              {/* rutas para la aplicación */}
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact path={routes.config} 
                component={ConfigPage} 
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact path="/listVehicles" 
                component={ListVehicles} 
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact 
                path="/Vehicles" 
                component={Vehicles} 
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact 
                path="/profile" 
                component={Profile} 
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact 
                path='/config/:idVehicle' 
                component={DetailVehicle}
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact 
                path="/history" 
                component={HistoryTrips} 
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact 
                path="/favorite-trips" 
                component={FavoriteTrips} 
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact 
                path="/trip-details/:idRoute" 
                component={TripDetails} 
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact 
                path="/editVehicle/:id" 
                component={EditVehicle} 
              />
              <PrivateRoute 
                isAuthenticated={isAuth} 
                exact 
                path="/map/:routes/:id" 
                component={TripDetailsMap} 
              />
        </Switch>
      </Suspense>
    </Router>  
  )
}

export default AppRouter
