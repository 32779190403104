import { Redirect, Route } from "react-router-dom"

const PrivateRoute = ({ component: Component, isAuthenticated, ...restProps }) => {
  return (
    <Route 
      {...restProps}
      component={
        (props) => (
          (isAuthenticated)
          ? <Component {...props} />
          : <Redirect to={'/'} />
        )
      }
    />
  )
}

export default PrivateRoute
