import { createContext, useReducer } from "react"
import AuthReducer from "./AuthReducer"
import { authTypes } from "./authTypesReducer"
import { firebase, firestore, googleProvider } from "../../config/configFirebase"
import { NOT_VALID_EXTENTIONS } from "../../config/GeneralConfig"
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { alertAuthLogin, alertAuthRegister } from "../../utils/alertAuth"

export const AuthContext = createContext()

const AuthState = ({children}) => {

		const initialState = {
			user			: null,
			loading		: false,
			authError : false,
			isAuth    : undefined,
		}

    const [authState, dispatch] = useReducer(AuthReducer, initialState)

		// Iniciar sesion con google
		const loginWithGoogle = async () => {
			const mySwal = withReactContent(Swal)
			try {
				const credentials = await firebase
													.auth()
													.signInWithPopup( googleProvider )

				let emailValid = credentials.user.email.split("@")
				let extentionValid = emailValid[1].split(".")

				if( NOT_VALID_EXTENTIONS.indexOf(extentionValid[0]) !== -1 ){
					mySwal.fire({
						title: "Por favor usar dominios empresariales",
        		icon : "error"
					})
					await firebase.auth().signOut()
					return;
				}

				dispatch({
					type	 : authTypes.LoginUser,
					payload: credentials.user
				})

				await firestore
					.collection("users")
					.doc(credentials.user.uid)
					.set(
						{
							email: credentials.user.email,
							name : credentials.user.displayName
						}
					);
				
			} catch (error) {
				console.log(error)
				callAlert(error?.code)
			}
		}

		// Registrar nuevo usuario
		const registerNewUser = async ( user, email, password ) => {
			try {
				const credentials = await firebase
														.auth()
														.createUserWithEmailAndPassword( email, password )
				
				await credentials.user.updateProfile({
					displayName: `${user.name} ${user.lastname}` 
				})

				dispatch({
					type	 : authTypes.LoginUser,
					payload: credentials.user
				})

				await firestore
					.collection("users")
					.doc(credentials.user.uid)
					.set(user);

			} catch (error) {
				console.log(error)
				callAlert(error?.code)
			}
		}

		// Enviar correo para cambio de contraseña
		const sendEmailChangePassword = async ( email ) => {
			try {
				await firebase.auth().sendPasswordResetEmail( email )
			} catch (error) {
				console.log(error)
				alertAuthRegister(error?.code)
			}
		}

		// Iniciar sesion con correo y contraseña
		const loginWithEmail = async ( email, password ) => {
			try {
				const credentials = await firebase
														.auth()
														.signInWithEmailAndPassword(email, password)
				dispatch({
					type	 : authTypes.LoginUser,
					payload: credentials.user
				})

			} catch (error) {
				console.log(error)
				callAlert(error?.code)
			}
		}
		
		// Al resfrescar navegador
		const getCurrentUser = () => {
			try {
				firebase.auth().onAuthStateChanged( async (user) => {
					if(user){
						dispatch({ 
							type   : authTypes.LoginUser, 
							payload: user
						})
						verifyExistConfigVehicle( user.uid )
					}else{
						dispatch({ type: authTypes.LogoutUser })
						await firebase.auth().signOut()
					}
				})
			} catch (error) {
				callAlert(error?.code)
			}
		}

		// Se agrega una configuracion de los vehiculos en caso de 
		// que no exista una para el usuario.
		const verifyExistConfigVehicle = async ( userId ) => {
			try {
				// se verifica que tenga una configuracion
				const userConfig = await firestore
							.collection('config_vehicle')
							.doc(userId)
							.get()

				// Si existe se DETIENE el flujo
				if( userConfig.exists ) return

				// Se obtine los vehiculos de configuracion
				// general
				const vehicles = await firestore
							.collection('vehicles_type')
							.get()
				
				
				let arrayVehicles = []
				vehicles.forEach( doc => {
					arrayVehicles.push({
						...doc.data(),
						id: doc.id
					})
				})

				// Se crea una coleccion para cada usuario
				const saveConfig = arrayVehicles.map( async (vehicle) => {
						return await firestore
							.collection('config_vehicle')
							.doc(userId)
							.collection('vehicles')
							.doc( vehicle.id )
							.set({...vehicle})
				})

				// Se resuelven el array de promesas
				await Promise.all( saveConfig )

				// Se obtienen las configuraciones por cada vehiculo
				arrayVehicles.map( async (vehicle) => {

					let resumeData = []
					
					const data = await firestore
						.collection('vehicles_type')
						.doc( vehicle.id )
						.collection('load_level')
						.get()

					resumeData.push({
						data : data.docs,
						id : vehicle.id
					})

					
					// se guardan las configuraciones por cada vehiculo
					resumeData.forEach( ( el ) => {
						el.data.map( async (doc) => {
							await firestore
							.collection('config_vehicle')
							.doc(userId)
							.collection('vehicles')
							.doc(el.id)
							.collection('load_level')
							.doc(doc.id)
							.set({
								...doc.data()
							})

						})
					})
				})

			} catch (error) {
				console.log(error)
			} 
		}
		

		const callAlert = ( code ) => alertAuthLogin(code)
		
    return (
      <AuthContext.Provider
				value={{
					authState,
					getCurrentUser,
					loginWithGoogle,
					loginWithEmail,
					registerNewUser,
					sendEmailChangePassword
				}}
			>
          {children}
      </AuthContext.Provider>
    )
}

export default AuthState
		