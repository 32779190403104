import { createContext, useReducer } from "react";
import { firestore } from "../../config/configFirebase";
import { typesVehicles } from "./typesVehicles";
import { VehiclesReducer } from "./VehiclesReducer";

export const VehicleContext = createContext()

const VehicleState = ({ children }) => {

  const initialState = {
    loading       : false,
    vehicles      : [],
    myVehicles    : [],
    configVehicle : []
  }

  const [state, dispatch] = useReducer( VehiclesReducer, initialState)

  // Listar todos los vehiculos a la hora de configurar
  const getVehicles = ( userId ) => {
    dispatch({ type: typesVehicles.InitAction })
    try {
      firestore
        .collection('config_vehicle')
        .doc(userId)
        .collection('vehicles')
        .where("type_injection", "==", "Electrónica")
        .orderBy("order")
        .onSnapshot( snap => {
          let vehicles = []
          snap.forEach( doc => {
            vehicles.push({
              id: doc.id,
              ...doc.data()
            })
          })
          dispatch({
            type: typesVehicles.GetVehicles,
            payload: vehicles
          })
        })
      dispatch({ type: typesVehicles.EndAction })
    } catch (error) {
      console.log(error)
      dispatch({ type: typesVehicles.EndAction })
    }
  }

  // obtinen el vehiculo a la hora de elegir configurarlo
  const getConfigVehicle = (idVehicle, userId) => {
    dispatch({ type: typesVehicles.InitAction })
    try {
      let generalConfig = {}
      firestore
        .collection('config_vehicle')
        .doc(userId)
        .collection('vehicles')
        .doc( idVehicle )
        .onSnapshot( snap => generalConfig.vehicle = snap.data() )
      
      firestore
        .collection('config_vehicle')
        .doc(userId)
        .collection('vehicles')
        .doc( idVehicle )
        .collection('load_level')
        .onSnapshot( snap => {
          let config = []
          snap.forEach( doc => {
            config.push({
              id: doc.id,
              ...doc.data(),
              ...generalConfig
            })
          })
      
          dispatch({
            type: typesVehicles.GetConfigVehicle,
            payload: config
          })
        })
        dispatch({ type: typesVehicles.EndAction })
    } catch (error) {
      console.log(error)
      dispatch({ type: typesVehicles.EndAction })
    }
  }
  
  

  return(
    <VehicleContext.Provider
      value={{
        state,
        getVehicles,
        getConfigVehicle
      }}
    >
      { children }
    </VehicleContext.Provider>
  )
}

export default VehicleState